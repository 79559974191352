"use client";

import { SVGProps } from "react";
import { useConfiguration } from "@/hooks/useConfiguration";

import MarineIcon from "@/public/assets/structureTypes/marine.svg";
import MobileHomeIcon from "@/public/assets/structureTypes/mobileHome.svg";
import CommercialIcon from "@/public/assets/structureTypes/commercial.svg";
import HouseIcon from "@/public/assets/structureTypes/house.svg";
import MultiFamilyDwellingIcon from "@/public/assets/structureTypes/multiFamilyDwelling.svg";
import WeatherIcon from "@/public/assets/structureTypes/weather.svg";
import OutdoorIcon from "@/public/assets/structureTypes/outdoor.svg";
import BrushIcon from "@/public/assets/structureTypes/brush.svg";
import NewsIcon from "@/public/assets/structureTypes/news.svg";
import TrafficIcon from "@/public/assets/structureTypes/traffic.svg";
import TestIcon from "@/public/assets/structureTypes/test.svg";
import EarthquakeIcon from "@/public/assets/structureTypes/earthquake.svg";
import { Typography } from "@mui/material";
import Image from "next/image";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const map = {
  brush: BrushIcon,
  commercial: CommercialIcon,
  marine: MarineIcon,
  mobileHome: MobileHomeIcon,
  multiFamilyDwelling: MultiFamilyDwellingIcon,
  house: HouseIcon,
  traffic: TrafficIcon,
  weather: WeatherIcon,
  outdoor: OutdoorIcon,
  news: NewsIcon,
  test: TestIcon,
  earthquake: EarthquakeIcon,
};

type Props = SVGProps<any> & {
  children?: string;
};

export function StructureTypeIcon(props: Props) {
  // @ts-ignore
  const Icon = props.children ? map[props.children] : null;

  return Icon ? (
    <Icon {...props} />
  ) : (
    <Typography
      sx={{
        fontSize: "2rem",
        textAlign: "center",
      }}
    >
      <WarningAmberIcon sx={{ color: props?.style?.color }} />
    </Typography>
  );
}

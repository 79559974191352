import { useQuery } from "@tanstack/react-query";
import { api } from "@/api/api";
import { User } from "@/types";

type Response = {
  user: User;
};

export function useSelf() {
  return useQuery({
    queryKey: ["self"],
    queryFn: async () => {
      const {
        data: { user: self },
      } = await api.get<Response>("auth/self");

      return self;
    },

    gcTime: Infinity,
    staleTime: Infinity,
  });
}

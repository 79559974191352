"use client";

import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#4487EE",
    },

    // @ts-ignore
    tertiary: {
      main: "#FFFFFF",
    },
  },

  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,

        style: {
          backgroundColor: "#4487EE",
          color: "#FFFFFF",
          height: "3.5rem",
          borderRadius: "8px",
          padding: "0 2rem",
          textTransform: "none",
          fontWeight: "600",
        },
      },

      styleOverrides: {
        root: {
          "&:disabled": {
            opacity: 0.5,
          },

          "&:focus": {
            outline: "3px solid #000000",
          },
        },
      },

      variants: [
        {
          props: {
            color: "secondary",
          },

          style: {
            backgroundColor: "#FFFFFF !important",
            color: "#263238 !important",
            border: "1px solid #CFD8DD",
          },
        },

        {
          props: {
            color: "error",
          },

          style: {
            backgroundColor: "#FFFFFF !important",
            color: "#263238 !important",
            border: "1px solid #DB4747",
          },
        },

        {
          props: {
            // @ts-ignore
            color: "tertiary",
          },

          style: {
            backgroundColor: "#FFFFFF !important",
            color: "#4487EE !important",
            border: "1px solid #4487EE",
          },
        },
      ],
    },

    MuiTab: {
      defaultProps: {
        style: {
          textTransform: "none",
        },
      },

      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        autoComplete: "off",
      },

      styleOverrides: {
        root: {},
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          height: "56px",
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",

          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E5E7E8",
          },
        },
      },
    },

    // @ts-ignore
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          "&:disabled": {
            opacity: 0.5,
          },

          "&:focus": {
            outline: "3px solid #000000",
          },
        },
      },
    },

    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderTopRightRadius: "16px",
          borderTopLeftRadius: "16px",

          backgroundColor: "#FFFFFF",

          "& .MuiDataGrid-columnHeadersInner": {
            background: "#4487EE",
            borderTopRightRadius: "16px",
            borderTopLeftRadius: "16px",
          },

          "& .MuiDataGrid-columnHeaderTitle": {
            color: "#FFFFFF",
            fontWeight: "600",
            fontSize: "1rem",
          },

          "& .MuiDataGrid-sortIcon": {
            color: "#FFFFFF",
          },

          "& .MuiDataGrid-menuIconButton": {
            color: "#FFFFFF",
          },

          "& .MuiDataGrid-cell--withRightBorder": {
            border: "1px solid #CFD8DD",
          },

          // '& .MuiDataGrid-columnHeaderTitleContainerContent': {
          //   margin: '0 auto',
          //   textAlign: 'center',
          // },
        },

        row: {
          minHeight: "54px !important",

          "& p": {
            fontWeight: "600 !important",
          },
        },

        cellContent: {
          fontWeight: "600 !important",
          textAlign: "center !important",
        },
      },

      defaultProps: {
        disableColumnMenu: true,
        disableColumnSorting: true,
      },
    },
  },
});

import { theme as coreTheme } from "@/theme";

export const theme = {
  colors: {
    brand: {
      primary: "#4487EE",
    },

    damage: {
      miscellaneous: "#1A878F",
      structural: "#F77B1D",
      fire: "#ED2B22",
      water: "#4487EE",
      electrical: "#FFBC1F",
    },
  },

  breakpoints: coreTheme.breakpoints,
};
